import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserInfo } from './services/api';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);  // 추가

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserInfo(token)
        .then(userInfo => {
          setIsAuthenticated(true);
          setUserRole(userInfo.role);
        })
        .catch(() => {
          // 토큰이 유효하지 않은 경우
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          setUserRole(null);
        });
    }
  }, []);

  const login = (token, role) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setUserRole(role);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserRole(null);
  };

  return (
    <AppContext.Provider value={{ 
      isAuthenticated, 
      setIsAuthenticated, 
      userRole, 
      setUserRole,
      login,
      logout,
      loading,  // 추가
      setLoading,  // 추가
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaShoppingCart, FaStore, FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';


const SidebarContainer = styled.nav`
  width: ${props => props.collapsed ? '60px' : '170px'};
  background-color: #3f51b5;
  color: white;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  
  @media (max-width: 768px) {
    width: ${props => props.collapsed ? '0' : '100%'};
    height: 100vh;
    left: ${props => props.collapsed ? '-60px' : '0'};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  align-self: center;
  margin-top: auto;
  padding: 10px;

  @media (max-width: 768px) {
    position: absolute;
    top: ${props => props.collapsed ? '50%' : '10px'};
    right: 10px;
    transform: ${props => props.collapsed ? 'translateY(-50%) rotate(90deg)' : 'none'};
  }
`;

const Logo = styled.h1`
  width: 100%;
  max-width: 150px; // 실제 필요한 최대 크기로 설정
  height: auto; // 비율 유지
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    justify-content: center;

    span {
      display: ${props => props.collapsed ? 'none' : 'inline'};
    }
  }
`;

const SubMenu = styled.div`
  padding-left: ${props => props.collapsed ? '0' : '20px'};
  display: ${props => props.collapsed ? 'none' : 'block'};
`;

const SubMenuItem = styled(MenuItem)`
  font-size: 0.9em;
`;

const menuItems = [
  {
    icon: <FaShoppingCart />,
    label: '네이버 쇼핑',
    subItems: [
      { label: '원부(가격비교)', path: '/naver/wonbu' },
      { label: '단일(단일상품)', path: '/naver/single' },
    ],
    expanded: true // 기본적으로 펼쳐있도록 설정
  },
  {
    icon: <FaMapMarkerAlt />,
    label: '네이버 플레이스',
    path: '/naver/place'
  },
];

function Sidebar({ collapsed, toggleSidebar, isMobile }) {
  const [expandedItems, setExpandedItems] = useState(
    menuItems.reduce((acc, item, index) => {
      acc[index] = item.expanded || false;
      return acc;
    }, {})
  );
  const navigate = useNavigate();

  const handleItemClick = (item, index) => {
    if (item.subItems) {
      setExpandedItems(prev => ({ ...prev, [index]: !prev[index] }));
    } else if (item.path) {
      navigate(item.path);
      if (isMobile) {
        toggleSidebar();  // 모바일에서 메뉴 항목 클릭 시 사이드바 닫기
      }
    }
  };

  const renderMenuItem = (item, index, level = 0) => {
    const isExpanded = expandedItems[index];

    return (
      <React.Fragment key={index}>
        <MenuItem
          collapsed={collapsed}
          onClick={() => handleItemClick(item, index)}
        >
          {item.icon}
          {(!collapsed || isMobile) && (
            <span>{item.label}</span>
          )}
          {item.subItems && !collapsed && (
            <span>
              {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
            </span>
          )}
        </MenuItem>
        {!collapsed && item.subItems && isExpanded && (
          <SubMenu>
            {item.subItems.map((subItem, subIndex) =>
              renderMenuItem(subItem, `${index}-${subIndex}`, level + 1)
            )}
          </SubMenu>
        )}
      </React.Fragment>
    );
  };

  return (
    <SidebarContainer collapsed={collapsed} isMobile={isMobile}>
      <Logo>{collapsed && !isMobile ? 'SR' : 'SlotRanker'}</Logo>
      {menuItems.map((item, index) => renderMenuItem(item, index))}
      <ToggleButton onClick={toggleSidebar} collapsed={collapsed}>
        {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </ToggleButton>
    </SidebarContainer>
  );
}

export default Sidebar;
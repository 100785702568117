import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { ArrowUp, ArrowDown, ExternalLink, Image, Edit, MessageCircle, Trophy } from 'lucide-react';
import EditProductModal from './EditProductModal';
import { toast } from 'react-toastify';
import { useAppContext } from '../../AppContext';


// 애니메이션 정의
const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const shine = keyframes`
  0% { background-position: -100px; }
  100% { background-position: 200px; }
`;

const getRankGradient = (rank) => {
  switch (rank) {
    case 1: return `linear-gradient(135deg, #fceabb 0%, #fccd4d 50%, #f8b500 51%, #fbdf93 100%)`; // 금
    case 2: return `linear-gradient(135deg, #e6e6e6 0%, #d9d9d9 50%, #cbcbcb 51%, #e0e0e0 100%)`; // 은 (더 진하게 조정)
    case 3: return `linear-gradient(135deg, #f7e1d7 0%, #e4b294 50%, #cf9c7a 51%, #ddb99a 100%)`; // 동
    default: return 'transparent';
  }
};

const getCardBackground = (rank) => {
  switch (rank) {
    case 1: return `linear-gradient(to bottom, rgba(252, 205, 77, 0.1), rgba(255, 255, 255, 0.8))`; // 금
    case 2: return `linear-gradient(to bottom, rgba(203, 203, 203, 0.1), rgba(255, 255, 255, 0.8))`; // 은
    case 3: return `linear-gradient(to bottom, rgba(207, 156, 122, 0.1), rgba(255, 255, 255, 0.8))`; // 동
    default: return 'white';
  }
};

const RankEmblem = styled.div`
  position: absolute;
  top: -25px;
  left: -25px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  color: #333;
  background: ${props => getRankGradient(props.rank)};
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
  border: 4px solid white;
  z-index: 10;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to right, 
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.3) 50%,
      rgba(255,255,255,0) 100%
    );
    transform: rotate(30deg);
    animation: ${shine} 3s infinite linear;
  }
`;

const TrophyIcon = styled(Trophy)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.3);
`;

const RankNumber = styled.span`
  position: relative;
  z-index: 1;
`;

const Card = styled.div`
  background: ${props => getCardBackground(props.rank)};
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  animation: ${slideUp} 0.5s ease-out;
  position: relative;
  
  ${props => props.rank && css`
    border: 4px solid;
    border-image: ${getRankGradient(props.rank)};
    border-image-slice: 1;
  `}

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform: translateY(-5px);
  }
`;

const CardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 16px;
  transition: transform 0.3s ease;
  object-fit: cover;

  ${Card}:hover & {
    transform: scale(1.1);
  }
`;

const DefaultLogo = styled(Image)`
  width: 50px;
  height: 50px;
  margin-right: 16px;
  color: #6b7280;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: #6b7280;
`;

const CardBody = styled.div`
  padding: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: #4b5563;
`;

const InfoValue = styled.span`
  color: #6b7280;
`;

const RankChange = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  ${props => (props.isPositive ? 'color: #10b981;' : 'color: #ef4444;')}
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.1);
  }
`;

const ExternalLinkButton = styled.a`
  display: flex;
  align-items: center;
  color: #3b82f6;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    color: #2563eb;
    transform: translateX(5px);
  }
`;

const CurrentRank = styled.span`
  color: #6b7280;
  font-weight: bold;
`;

const EditButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #2563eb;
  }
`;

const KakaoButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FEE500;
  color: #000000;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 30px;
  
  &:hover {
    background-color: #FDD835;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;


const ProductCard = ({ product, onClick, onUpdate, displayRank }) => {
  const { userRole } = useAppContext();
  const isAdmin = userRole === 'admin';
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState(product);
  const rankChange = product.previousRank - product.currentRank;
  const logoUrl = product.logo || product.slot_logo_image_url;

  useEffect(() => {
    setEditedProduct(product);

  }, [product]);

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
  };

  const handleUpdate = async (updatedProduct) => {
    try {
      await onUpdate(updatedProduct.id);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error('제품 업데이트에 실패했습니다.');
    }
  };


  const getFullImageUrl = (url) => {
    return `https://api.slotranker.kr${url}`
  };

  const fullLogoUrl = getFullImageUrl(logoUrl);

  const handleImageError = (e) => {
    e.target.onerror = null; // 무한 루프 방지
    e.target.style.display = 'none'; // 이미지 숨기기
    e.target.nextSibling.style.display = 'block'; // 기본 로고 표시
  };


  return (
    <>
      <Card onClick={() => onClick(product)} rank={displayRank}>
        {displayRank && (
          <RankEmblem rank={displayRank}>
            <TrophyIcon size={40} />
            <RankNumber>{displayRank}</RankNumber>
          </RankEmblem>
        )}
        {isAdmin && <EditButton onClick={handleEdit}><Edit size={16} /></EditButton>}
        <CardHeader>
          {fullLogoUrl ? (
            <>
              <Logo
                src={fullLogoUrl}
                alt={product.title}
                onError={handleImageError}
              />
              <DefaultLogo style={{ display: 'none' }} />
            </>
          ) : (
            <DefaultLogo />
          )}
          <div>
            <CardTitle>{product.title}</CardTitle>
            <CardSubtitle>{product.category}</CardSubtitle>
          </div>
        </CardHeader>
        <CardBody>
          <InfoRow>
            <InfoLabel>현재 순위:</InfoLabel>
            <CurrentRank>#{product.currentRank}</CurrentRank>
          </InfoRow>
          <InfoRow>
            <InfoLabel>최초 순위:</InfoLabel>
            <CurrentRank>#{product?.previousRank || 'N/A'}</CurrentRank>
          </InfoRow>
          <InfoRow>
            <InfoLabel>순위 변동:</InfoLabel>
            <RankChange isPositive={rankChange > 0}>
              {rankChange > 0 ? <ArrowUp size={16} /> : rankChange < 0 ? <ArrowDown size={16} /> : '-'}
              {Math.abs(rankChange)}
            </RankChange>
          </InfoRow>
          <InfoRow>
            <InfoLabel>월간 검색량:</InfoLabel>
            <InfoValue>{product.monthlySearches.toLocaleString()}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>슬롯 갯수:</InfoLabel>
            <InfoValue>{product.slot_cnt || 'N/A'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>최근 업데이트:</InfoLabel>
            <InfoValue>{product.lastUpdate}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>등록일:</InfoLabel>
            <InfoValue>{product.registrationDate}</InfoValue>
          </InfoRow>
          {isAdmin && (
            <ExternalLinkButton href={product.link} target="_blank" rel="noopener noreferrer">
              상품 링크 <ExternalLink size={16} style={{ marginLeft: '4px' }} />
            </ExternalLinkButton>
          )}
          <KakaoButton
            href="https://open.kakao.com/o/shi17RQg"
            target="_blank"
            rel="noopener noreferrer"
            id="kakao-assign-button"
            data-event-category="Kakao"
            data-event-action="Click"
            data-event-label="Assign Manager"
          >
            담당자 배정 <MessageCircle size={16} style={{ marginLeft: '8px' }} />
          </KakaoButton>
        </CardBody>
      </Card>
      {isAdmin && isEditing && (
        <EditProductModal
          product={product}
          onClose={handleCloseEdit}
          onSubmit={handleUpdate}
        />
      )}
    </>
  );
};

export default ProductCard;

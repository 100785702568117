import React from 'react';
import styled, { keyframes } from 'styled-components';
import { RefreshCw } from 'lucide-react';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.5);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`;

const LiveIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #3b82f6;
  animation: ${pulseAnimation} 2s infinite;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #e0f2fe;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    text-align: center;
  }
`;

const RotatingIcon = styled(RefreshCw)`
  animation: spin 1s linear infinite;
  margin-right: 8px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const UpdateText = styled.span`
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const UpdateTime = styled.span`
  font-size: 14px;
  color: #6b7280;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 12px;
  }
`;

const LiveIndicator = ({ isUpdating, lastUpdateTime }) => (
  <LiveIndicatorContainer>
    <RotatingIcon size={16} />
    <UpdateText>실시간 업데이트 중</UpdateText>
    <UpdateTime>
      {isUpdating ? '업데이트 중...' : `마지막 업데이트: ${lastUpdateTime.toLocaleTimeString()}`}
    </UpdateTime>
  </LiveIndicatorContainer>
);

export default LiveIndicator;

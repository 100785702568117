import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAppContext } from '../../AppContext';
import ProductRankingDashboard from './ProductRankingDashboard';
import { formatProductData } from '../../utils/productUtils';

const CoupangDashboard = () => {
  const { setProducts, setLoading, setError } = useAppContext();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.slotranker.kr/running-slots?platform=coupang');
      const formattedProducts = response.data.slots
        .map(formatProductData)
        .filter(Boolean)
        .sort((a, b) => b.rankingChange - a.rankingChange);
      setProducts(formattedProducts);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  }, [setProducts, setLoading, setError]);

  useEffect(() => {
    fetchData();
    const updateInterval = setInterval(fetchData, 360000); // 1시간 마다 업데이트

    return () => clearInterval(updateInterval);
  }, [fetchData]);

  return <ProductRankingDashboard title="Coupang 상품 랭킹" />;
};

export default CoupangDashboard;
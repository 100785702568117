import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { X, Lock, TrendingUp, TrendingDown, Minus, MessageCircle } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #f8fafc;
  border-radius: 16px;
  width: 95%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  background-color: #ffffff;
  padding: 20px 30px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1e293b;
  margin: 0;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #64748b;
  transition: color 0.3s;

  &:hover {
    color: #334155;
  }
`;

const ModalBody = styled.div`
  padding: 30px;
`;

const ChartContainer = styled.div`
  height: 250px; // 높이를 조금 줄임
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 10px 20px 0; // 오른쪽 패딩을 줄임
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const FilterButton = styled.button`
  background-color: ${props => props.active ? '#3b82f6' : '#e2e8f0'};
  color: ${props => props.active ? '#ffffff' : '#1e293b'};
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;

  &:hover {
    background-color: ${props => props.active ? '#2563eb' : '#cbd5e1'};
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const InfoItem = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

const InfoLabel = styled.span`
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  color: #64748b;
  font-size: 14px;
`;

const InfoValue = styled.span`
  color: #1e293b;
  font-size: 16px;
  font-weight: 500;
`;

const RestrictedInfoValue = styled(InfoValue)`
  filter: blur(4px);
  user-select: none;
`;

const LockedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(248, 250, 252, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #94a3b8;
`;

const InfoSection = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  color: #1e293b;
  margin-bottom: 15px;
  font-weight: 600;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 10px;
`;

const RankChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.change > 0 ? '#10b981' : props.change < 0 ? '#ef4444' : '#6b7280'};
`;

const KakaoButton = styled.a`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FEE500;
  color: #000000;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 30px;
  text-align: center;
  
  &:hover {
    background-color: #FDD835;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;


const ProductDetailModal = ({ product, onClose, isLoggedIn }) => {
  const [filterType, setFilterType] = useState('daily');

  const handleKakaoButtonClick = useCallback((e) => {
    // 데이터 레이어 이벤트 추가
    window.dataLayer.push({
      'event': 'kakaoInfoButtonClick',
      'buttonId': 'kakao-info-button',
      'eventCategory': 'Kakao',
      'eventAction': 'Click',
      'eventLabel': 'Full Info'
    });
  }, []);


  const chartData = useMemo(() => {
    if (!product || !product.chartData) {
      return [];
    }

    if (filterType === 'daily') {
      const dailyData = {};
      product.chartData.forEach(item => {
        const date = new Date(item.date).toLocaleDateString();
        if (!dailyData[date] || item.rank < dailyData[date].rank) {
          dailyData[date] = { date, rank: item.rank };
        }
      });
      return Object.values(dailyData);
    } else {
      return product.chartData;
    }
  }, [product, filterType]);

  const renderRankChange = (change) => {
    if (change > 0) {
      return <RankChange change={change}><TrendingUp size={16} style={{ marginRight: '4px' }} /> +{change}</RankChange>;
    } else if (change < 0) {
      return <RankChange change={change}><TrendingDown size={16} style={{ marginRight: '4px' }} /> {change}</RankChange>;
    } else {
      return <RankChange change={change}><Minus size={16} style={{ marginRight: '4px' }} /> 0</RankChange>;
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <Title>{product ? product.title : 'Loading...'} 상세 정보</Title>
          <CloseButton onClick={onClose}>
            <X />
          </CloseButton>
        </ModalHeader>

        <ModalBody>
          <FilterContainer>
            <FilterButton
              active={filterType === 'daily'}
              onClick={() => setFilterType('daily')}
            >
              일별
            </FilterButton>
            <FilterButton
              active={filterType === 'hourly'}
              onClick={() => setFilterType('hourly')}
            >
              시간별
            </FilterButton>
          </FilterContainer>

          <ChartContainer>
            {chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData} margin={{ top: 5, right: 10, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" />
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 10, fill: '#64748b' }}
                    tickFormatter={(value) => {
                      const date = new Date(value);
                      return filterType === 'daily'
                        ? `${date.getMonth() + 1}/${date.getDate()}`
                        : `${date.getHours()}:00`;
                    }}
                    interval={'preserveStartEnd'}
                  />
                  <YAxis
                    reversed
                    domain={['dataMin', 'dataMax']}
                    tick={{ fontSize: 10, fill: '#64748b' }}
                    width={30} // YAxis의 너비를 줄임
                  />
                  <Tooltip
                    labelFormatter={(value) => new Date(value).toLocaleString()}
                    formatter={(value) => [`${value}위`, '순위']}
                    contentStyle={{
                      backgroundColor: '#ffffff',
                      border: 'none',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      fontSize: '12px', // 폰트 크기를 줄임
                      padding: '5px' // 패딩을 줄임
                    }}
                  />
                  <Legend wrapperStyle={{ fontSize: '12px' }} /> // Legend 폰트 크기를 줄임
                  <Line type="monotone" dataKey="rank" name="순위" stroke="#3b82f6" strokeWidth={2} activeDot={{ r: 6 }} />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div>데이터가 없습니다.</div>
            )}
          </ChartContainer>

          <InfoSection>
            <SectionTitle>기본 정보</SectionTitle>
            <InfoGrid>
              <InfoItem>
                <InfoLabel>슬롯 유형</InfoLabel>
                <InfoValue>{product?.slotType || 'N/A'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>쇼핑몰 유형</InfoLabel>
                <InfoValue>{product?.mallType || 'N/A'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>등록일</InfoLabel>
                <InfoValue>{product?.registrationDate || 'N/A'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>최근 업데이트</InfoLabel>
                <InfoValue>{product?.lastUpdate || 'N/A'}</InfoValue>
              </InfoItem>
            </InfoGrid>
          </InfoSection>

          <InfoSection>
            <SectionTitle>순위 정보</SectionTitle>
            <InfoGrid>
              <InfoItem>
                <InfoLabel>현재 순위</InfoLabel>
                <InfoValue>{product?.currentRank || 'N/A'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>초기 순위</InfoLabel>
                <InfoValue>{product?.previousRank || 'N/A'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>순위 변동</InfoLabel>
                <InfoValue>{renderRankChange(product?.rankingChange)}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>월간 검색량</InfoLabel>
                <InfoValue>{product?.monthlySearches?.toLocaleString() || 'N/A'}</InfoValue>
              </InfoItem>
            </InfoGrid>
          </InfoSection>

          <InfoSection>
            <SectionTitle>제한된 정보</SectionTitle>
            <InfoGrid>
              <InfoItem>
                <InfoLabel>슬롯당 유입수</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{product?.visits_per_slot || 'N/A'}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{product?.visits_per_slot || 'N/A'}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>슬롯 기간</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{`${product?.slotDuration || 'N/A'} 일`}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{`${product?.slotDuration || 'N/A'} 일`}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>구동 종료 시간</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{product?.slotEndTime || 'N/A'}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{product?.slotEndTime || 'N/A'}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>AS 여부</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{product?.slotAs || 'N/A'}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{product?.slotAs || 'N/A'}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>공급가</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{`${product?.slotSupplyPrice?.toLocaleString() || 'N/A'}원`}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{`${product?.slotSupplyPrice?.toLocaleString() || 'N/A'}원`}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
              <InfoItem>
                <InfoLabel>일일 비용</InfoLabel>
                {isLoggedIn ? (
                  <InfoValue>{`${product?.slotDailyCost?.toLocaleString() || 'N/A'}원`}</InfoValue>
                ) : (
                  <>
                    <RestrictedInfoValue>{`${product?.slotDailyCost?.toLocaleString() || 'N/A'}원`}</RestrictedInfoValue>
                    <LockedOverlay>
                      <Lock size={16} />
                    </LockedOverlay>
                  </>
                )}
              </InfoItem>
            </InfoGrid>
          </InfoSection>

          <KakaoButton
            href="https://open.kakao.com/o/shi17RQg"
            target="_blank"
            rel="noopener noreferrer"
            id="kakao-info-button"
            data-event-category="Kakao"
            data-event-action="Click"
            data-event-label="Full Info"
            onClick={handleKakaoButtonClick}
          >
            <span>전체 정보 보기</span>
            <span>(카카오톡 채팅)</span>
            <MessageCircle size={16} style={{ marginTop: '8px' }} />
          </KakaoButton>
        </ModalBody>
      </ModalContent>
    </ModalOverlay >
  );
};

export default ProductDetailModal;
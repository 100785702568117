import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AppProvider, useAppContext } from './AppContext';
import { fetchUserInfo } from './services/api';
import NaverWonbuDashboard from './components/dashboard/NaverWonbuDashboard';
import NaverSingleDashboard from './components/dashboard/NaverSingleDashboard';
import CoupangDashboard from './components/dashboard/CoupangDashboard';
import NaverPlaceDashboard from './components/dashboard/NaverPlaceDashboard';
import Sidebar from './components/layout/Sidebar';
import Login from './components/auth/Login';
import './App.css';

function AppContent() {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(window.innerWidth <= 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { isAuthenticated } = useAppContext();
  const { login, logout } = useAppContext();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSidebarCollapsed(mobile);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserInfo(token)
        .then(userInfo => {
          login(token, userInfo.role);
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
          logout();
        });
    }
  }, [login, logout]);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Router>
      <div className="App">
        <Sidebar
          collapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
        />
        <div
          style={{
            marginLeft: !isMobile ? (sidebarCollapsed ? '60px' : '170px') : '0',
            transition: 'margin-left 0.3s ease',
            padding: '20px'
          }}
        >
          {isMobile && (
            <button onClick={toggleSidebar} className="mobile-toggle-btn">
              ☰
            </button>
          )}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/naver" element={<NaverWonbuDashboard />} />
            <Route path="/naver/wonbu" element={<NaverWonbuDashboard />} />
            <Route path="/naver/single" element={<NaverSingleDashboard />} />
            <Route path="/naver/place" element={<NaverPlaceDashboard />} />
            <Route path="/coupang" element={<CoupangDashboard />} />
            <Route path="/" element={<Navigate replace to="/naver/wonbu" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

export default App;

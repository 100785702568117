import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { X, Upload } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  padding-right: 30px;
`;


const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;

`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #2563eb;
  }
`;

const ImageUploadArea = styled.div`
  border: 2px dashed #ddd;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
`;




const EditProductModal = ({ product, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        platform_name: '',
        slot_name: '',
        keyword: '',
        nvmid: '',
        slot_cnt: '',
        mall_type: '',
        visits_per_slot: '',
        slot_type: '',
        slot_duration: '',
        slot_setup_date: '',
        slot_end_time: '',
        slot_as: '',
        slot_supply_price: '',
        slot_daily_cost: '',
        slot_logo_image_url: '',
        category: '',
        status: '',
    });

    useEffect(() => {
        // product 객체의 키 값을 formData 형식에 맞게 변환
        setFormData({
            platform_name: product.platform || '',
            slot_name: product.title || '',
            keyword: product.keyword || '',
            nvmid: product.nvmid || '',
            slot_cnt: product.slot_cnt || '',
            mall_type: product.mallType || '',
            visits_per_slot: product.visits_per_slot || '',
            slot_type: product.slotType || '',
            slot_duration: product.slotDuration || '',
            slot_setup_date: product.slot_setup_date || '',
            slot_end_time: product.slotEndTime || '',
            slot_as: product.slotAs || '',
            slot_supply_price: product.slotSupplyPrice || '',
            slot_daily_cost: product.slotDailyCost || '',
            slot_logo_image_url: product.slot_logo_image_url || '',
            category: product.category || '',
            status: product.status || '',
        });
    }, [product]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        const imageFormData = new FormData();
        imageFormData.append('image', file);

        try {
            const response = await axios.post('https://api.slotranker.kr/upload-image/', imageFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setFormData(prevData => ({ ...prevData, slot_logo_image_url: response.data.url }));
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('이미지 업로드에 실패했습니다.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData)
        try {
            const response = await axios.put(`https://api.slotranker.kr/slots/${product.id}`, formData);

            if (response.status === 200) {
                onSubmit(response.data);
                toast.success('슬롯이 성공적으로 수정되었습니다!');
            }
        } catch (error) {
            console.error('Error updating slot:', error);
            toast.error('슬롯 수정에 실패했습니다.');
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <ModalOverlay onClick={handleOverlayClick}>
            <ModalContent>
                <CloseButton onClick={onClose}><X size={20} /></CloseButton>
                <Title>슬롯 수정</Title>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="platform_name">플랫폼명</Label>
                        <Input type="text" id="platform_name" name="platform_name" value={formData.platform_name} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_name">슬롯명</Label>
                        <Input type="text" id="slot_name" name="slot_name" value={formData.slot_name} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="category">카테고리</Label>
                        <Input type="text" id="category" name="category" value={formData.category} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="keyword">키워드</Label>
                        <Input type="text" id="keyword" name="keyword" value={formData.keyword} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="nvmid">NVMID</Label>
                        <Input type="text" id="nvmid" name="nvmid" value={formData.nvmid} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_cnt">슬롯 갯수 (중첩)</Label>
                        <Input type="number" id="slot_cnt" name="slot_cnt" value={formData.slot_cnt} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="mall_type">몰 타입</Label>
                        <Select id="mall_type" name="mall_type" value={formData.mall_type} onChange={handleChange} required>
                            <option value="">선택하세요</option>
                            <option value="단일">단일</option>
                            <option value="원부">원부</option>
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="visits_per_slot">1슬롯당 유입수</Label>
                        <Input type="number" id="visits_per_slot" name="visits_per_slot" value={formData.visits_per_slot} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_type">슬롯 타입</Label>
                        <Select id="slot_type" name="slot_type" value={formData.slot_type} onChange={handleChange} required>
                            <option value="">선택하세요</option>
                            <option value="리워드">리워드</option>
                            <option value="유입플">유입플</option>
                            <option value="복합플">복합플</option>
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_duration">구동 기간(일)</Label>
                        <Input type="number" id="slot_duration" name="slot_duration" value={formData.slot_duration} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_setup_date">슬롯 세팅일자</Label>
                        <Input type="text" id="slot_setup_date" name="slot_setup_date" value={formData.slot_setup_date} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_end_time">세팅 마감 시간</Label>
                        <Input type="text" id="slot_end_time" name="slot_end_time" value={formData.slot_end_time} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_as">AS</Label>
                        <Select id="slot_as" name="slot_as" value={formData.slot_as} onChange={handleChange} required>
                            <option value="">선택하세요</option>
                            <option value="가능">가능</option>
                            <option value="불가능">불가능</option>
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_supply_price">공급가</Label>
                        <Input type="number" id="slot_supply_price" name="slot_supply_price" value={formData.slot_supply_price} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="slot_daily_cost">1일 비용</Label>
                        <Input type="number" id="slot_daily_cost" name="slot_daily_cost" value={formData.slot_daily_cost} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="status">구동상태</Label>
                        <Select id="status" name="status" value={formData.status || "RUNNING"} onChange={handleChange} required>
                            <option value="">선택하세요</option>
                            <option value="running">RUNNING</option>
                            <option value="paused">PAUSED</option>
                            <option value="stopped">STOPPED</option>
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label>로고 업로드</Label>
                        <ImageUploadArea onClick={() => document.getElementById('logoUpload').click()}>
                            {formData.slot_logo_image_url ? (
                                <img src={`https://api.slotranker.kr/${formData.slot_logo_image_url}`} alt="Slot Logo" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            ) : (
                                <><Upload /> 이미지를 업로드하세요</>
                            )}
                        </ImageUploadArea>
                        <input
                            type="file"
                            id="logoUpload"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                            accept="image/*"
                        />
                    </FormGroup>
                    <SubmitButton type="submit">슬롯 수정</SubmitButton>
                </Form>
            </ModalContent>
        </ModalOverlay>
    );
};

export default EditProductModal;

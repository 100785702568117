import React, { useState } from 'react';
import styled from 'styled-components';
import { Search, Calendar, List, Tag, ChevronDown, ChevronUp } from 'lucide-react';

const FilterContainer = styled.div`
  background-color: #f3f4f6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const FilterTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #4b5563;
`;

const FilterContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);

  @media (min-width: 768px) {
    flex: 1 1 calc(33.333% - 10px);
    min-width: 200px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #1f2937;
  cursor: pointer;
  outline: none;

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #1f2937;
  outline: none;

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
`;

const FilterBar = ({ filters, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterContainer>
      <FilterHeader onClick={() => setIsOpen(!isOpen)}>
        <FilterTitle>필터</FilterTitle>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </FilterHeader>
      <FilterContent isOpen={isOpen}>
        <FilterGroup>
          <Label><Search size={16} /> 슬롯명</Label>
          <Input
            type="text"
            value={filters.title || ''}
            onChange={(e) => onFilterChange('title', e.target.value)}
            placeholder="검색..."
          />
        </FilterGroup>
        <FilterGroup>
          <Label><List size={16} /> 카테고리</Label>
          <Select
            value={filters.category || ''}
            onChange={(e) => onFilterChange('category', e.target.value)}
          >
            <option value="">전체</option>
            {/* 카테고리 옵션들을 동적으로 추가 */}
          </Select>
        </FilterGroup>
        <FilterGroup>
          <Label><Calendar size={16} /> 등록일자</Label>
          <Input
            type="date"
            value={filters.registrationDate || ''}
            onChange={(e) => onFilterChange('registrationDate', e.target.value)}
          />
        </FilterGroup>
        <FilterGroup>
          <Label><Tag size={16} /> 슬롯 갯수</Label>
          <Select
            value={filters.slot_cnt || ''}
            onChange={(e) => onFilterChange('slot_cnt', e.target.value)}
          >
            <option value="">전체</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            {/* 슬롯 갯수 옵션들을 동적으로 추가 */}
          </Select>
        </FilterGroup>
        <FilterGroup>
          <Label><Tag size={16} /> 슬롯 유형</Label>
          <Select
            value={filters.slotType || ''}
            onChange={(e) => onFilterChange('slotType', e.target.value)}
          >
            <option value="">전체</option>
            <option value="유입플">유입플</option>
            <option value="리워드">리워드</option>
            <option value="복합플">복합플</option>
            {/* 슬롯 유형 옵션들을 동적으로 추가 */}
          </Select>
        </FilterGroup>
      </FilterContent>
    </FilterContainer>
  );
};

export default FilterBar;

export const formatProductData = (data) => {
  if (!data) {
    console.error('No data provided');
    return null;
  }

  // rankings가 없거나 배열이 아닌 경우 빈 배열로 초기화
  const rankings = Array.isArray(data.rankings) ? data.rankings : [];

  // rankings를 날짜순으로 정렬
  rankings.sort((a, b) => new Date(a.date) - new Date(b.date));

  const firstRanking = rankings.length > 0 ? rankings[0] : null;
  const latestRanking = rankings.length > 0 ? rankings[rankings.length - 1] : null;

  // pc_search_month와 mo_search_month가 없는 경우를 대비해 기본값 0으로 설정
  const monthlySearches = ((latestRanking?.pc_search_month || 0) + (latestRanking?.mo_search_month || 0)) || 0;

  const currentRank = latestRanking ? latestRanking.ranking : 'N/A';
  const initialRank = firstRanking ? firstRanking.ranking : 'N/A';
  const rankingChange = typeof currentRank === 'number' && typeof initialRank === 'number' 
    ? initialRank - currentRank 
    : 0;

  // 차트 데이터 준비
  const chartData = rankings.map(rank => ({
    date: new Date(rank.date).toISOString(),
    rank: rank.ranking
  }));

  return {
    id: data.id || '',
    title: data.slot_name || '',
    logo: data.slot_logo_image_url || '',
    category: data.category || '',
    link: data.keyword ? `https://search.shopping.naver.com/search/all?query=${encodeURIComponent(data.keyword)}` : '',
    lastUpdate: latestRanking ? new Date(latestRanking.date).toLocaleString() : 'N/A',
    registrationDate: data.created_at ? formatKoreanDateTime(data.created_at) : 'N/A',
    visits_per_slot: data.visits_per_slot || 0,
    currentRank,
    previousRank: initialRank,
    rankingChange,
    platform: data.platform_name || '',
    nvmid: data.nvmid || '',
    mallType: data.mall_type || '',
    slotType: data.slot_type || '',
    slotDuration: data.slot_duration || '',
    slotEndTime: data.slot_end_time || '',
    slot_cnt: data.slot_cnt || '',
    slot_setup_date: data.slot_setup_date || '',
    slotAs: data.slot_as || '',
    slotSupplyPrice: data.slot_supply_price || 0,
    slotDailyCost: data.slot_daily_cost || 0,
    status: data.status || '',
    monthlySearches,
    keyword: data.keyword || '',
    chartData,
  };
};

export const formatKoreanDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  
  try {
    const date = new Date(dateString);
    const koreanTime = date.toLocaleString('ko-KR', {
      timeZone: 'Asia/Seoul',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return koreanTime.replace(/\. /g, '-').replace('.', ' ');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

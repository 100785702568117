import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from '../../AppContext'; 

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const LoginForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #1877f2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #166fe5;
  }
`;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAppContext();  // AppContext에서 login 함수 가져오기
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // 로그인 요청
          const response = await axios.post('https://api.slotranker.kr/token',
            new URLSearchParams({
              'username': username,
              'password': password
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );
    
          const token = response.data.access_token;
    
          // 사용자 정보 요청
          try {
            const userInfoResponse = await axios.get('https://api.slotranker.kr/users/me', {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
    
            const userRole = userInfoResponse.data.role;
    
            // AppContext의 login 함수 호출
            login(token, userRole);
    
            // 로컬 스토리지에 저장
            localStorage.setItem('token', token);
            localStorage.setItem('role', userRole);
    
            navigate('/naver/wonbu');
          } catch (userInfoError) {
            console.error('Error fetching user info:', userInfoError);
            // 사용자 정보를 가져오는 데 실패하더라도 기본 역할로 로그인 처리
            login(token, 'user');
            localStorage.setItem('token', token);
            localStorage.setItem('role', 'user');
            navigate('/naver/wonbu');
          }
        } catch (error) {
          console.error('Login failed:', error);
          alert('Login failed. Please try again.');
        }
      };
  

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit">Login</Button>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;

import axios from 'axios';

const API_URL = 'https://api.slotranker.kr';  // 백엔드 서버 URL

export const loginUser = async (username, password) => {
  const response = await axios.post(`${API_URL}/token`, {
    username,
    password
  });
  return response.data;
};

export const fetchUserInfo = async (token) => {
  const response = await axios.get(`${API_URL}/users/me`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
};

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Plus } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import ProductCard from './ProductCard';
import LiveIndicator from './LiveIndicator';
import AddNewProductModal from './AddNewProductModal';
import ProductDetailModal from './ProductDetailModal';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const DashboardContainer = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CenterContainer = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;


const AddButton = styled.button`
  background-color: #10b981;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #059669;
    tra: scale(1.05);
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// const CardGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//   gap: 20px;
// `;

const ProductRankingDashboard = ({ title, products, onUpdateProduct }) => {
  const { loading, error, userRole } = useAppContext();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [isUpdating, setIsUpdating] = useState(false);
  const isAdmin = userRole === 'admin';

  useEffect(() => {
    const updateTimer = setInterval(() => {
      setIsUpdating(true);
      // 여기에 실제 데이터 갱신 로직을 추가할 수 있습니다.
      // 예: fetchLatestData();
      setLastUpdateTime(new Date());
      setIsUpdating(false);
    }, 30000); // 30초마다 실행

    return () => clearInterval(updateTimer); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  // const handleCardClick = (product) => {
  //   setSelectedProduct(product);
  // };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleAddNewClick = () => {
    setShowAddProductModal(true);
  };

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false);
  };

  const handleAddProduct = (newProductData) => {
    setShowAddProductModal(false);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <DashboardContainer>
      <Header>
        <TitleContainer>
          <Title>{title}</Title>
          <CenterContainer>
            <LiveIndicator isUpdating={loading} lastUpdateTime={lastUpdateTime} />
          </CenterContainer>
        </TitleContainer>
        <ButtonContainer>
          {isAdmin && (
            <AddButton onClick={handleAddNewClick}>
              <Plus size={16} style={{ marginRight: '4px' }} />
              새 상품 추가
            </AddButton>
          )}
        </ButtonContainer>
      </Header>
      {isAdmin && showAddProductModal && (
        <AddNewProductModal
          onClose={handleCloseAddProductModal}
          onSubmit={handleAddProduct}
        />
      )}
      <ProductGrid>
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            product={product}
            onClick={handleProductClick}
            onUpdate={onUpdateProduct}
            displayRank={index < 3 ? index + 1 : null}
          />
        ))}
      </ProductGrid>
      {selectedProduct && (
        <ProductDetailModal
          product={selectedProduct}
          onClose={handleCloseModal}
        />
      )}
    </DashboardContainer>
  );
};

export default ProductRankingDashboard;
import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { useAppContext } from '../../AppContext';
import ProductRankingDashboard from './ProductRankingDashboard';
import { formatProductData } from '../../utils/productUtils';
import FilterBar from './FilterBar';
// import AllRankings from './AllRankings';

const NaverPlaceDashboard = () => {
  const { setLoading, setError } = useAppContext();
  const [products, setProducts] = useState([]);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({
    title: '',
    category: '',
    registrationDate: '',
    slotCount: '',
    slotType: '',
  });


  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.slotranker.kr/running-slots?platform=naver_place');
      const formattedProducts = response.data.slots
        .map(formatProductData)
        .filter(Boolean)
        .sort((a, b) => b.rankingChange - a.rankingChange);
      setProducts(formattedProducts);
      setIsInitialDataLoaded(true);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to load initial data');
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError]);

  const updateProductData = useCallback(async (productId) => {
    try {
      const response = await axios.get(`https://api.slotranker.kr/slots/${productId}/with_rankings`);
      const updatedProduct = formatProductData(response.data);
      setProducts(prevProducts => 
        prevProducts.map(product => 
          product.id === productId ? updatedProduct : product
        )
      );
    } catch (error) {
      console.error('Error updating product data:', error);
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    const updateInterval = setInterval(() => {
      products.forEach(product => updateProductData(product.id));
    }, 21600000); // 6시간마다 업데이트

    return () => clearInterval(updateInterval);
  }, [isInitialDataLoaded, products, updateProductData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  useEffect(() => {
    let result = products;

    if (filters.title) {
      result = result.filter(product => product.title.includes(filters.title));
    }
    if (filters.category) {
      result = result.filter(product => product.category === filters.category);
    }
    if (filters.registrationDate) {
      result = result.filter(product => product.registrationDate === filters.registrationDate);
    }
    if (filters.slotCount) {
      result = result.filter(product => product.slotCount === parseInt(filters.slotCount));
    }
    if (filters.slotType) {
      result = result.filter(product => product.slotType === filters.slotType);
    }

    setFilteredProducts(result);
  }, [filters, products]);

  if (!isInitialDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
    {/* <AllRankings /> */}
    <FilterBar filters={filters} onFilterChange={handleFilterChange} />
    <ProductRankingDashboard 
      title="Naver 플레이스 랭킹" 
      products={filteredProducts} 
      onUpdateProduct={updateProductData}
    />
    </>
  );
};

export default NaverPlaceDashboard;
